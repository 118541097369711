import React, { Component } from "react";
import TransitionLink, { TransitionPortal } from "gatsby-plugin-transition-link";
import { TimelineMax, Power1 } from "gsap/all";
import $ from "jquery"

export default class TimelineArticleImgAnim extends Component {
  constructor(props) {
    super(props);

    this.vertical = this.vertical.bind(this);

    this.cover = React.createRef();
    this.imgLink = React.createRef();
  }

  // componentDidMount(){
  // const imgLinkPositionTop = $(this.imgLink).offset().top;
  // const imgLinkPositionLeft = $(this.imgLink).offset().left;

  // $(this.cover).css({top: imgLinkPositionTop, left: imgLinkPositionLeft});
  // }

  vertical = ({ node, props: { length: seconds }, direction }) => {
    const imgLinkPositionTop = $(this.imgLink).offset().top;
    const imgLinkPositionLeft = $(this.imgLink).offset().left;
    const imgLinkWidth = $(this.imgLink).width();
    const imgLinkHeight = $(this.imgLink).height();
    $(this.cover).css({ top: imgLinkPositionTop, left: imgLinkPositionLeft });

    const wait = seconds / 10;
    const half = (seconds - wait);
    // const half = 10;

    return new TimelineMax()
      // .set(this.cover, { y: directionFrom })
      .set(this.cover, {
        // x: imgLinkPositionLeft,
        // y: imgLinkPositionTop,
        width: imgLinkWidth,
        height: imgLinkHeight
      })
      .to(this.cover, half, {
        // x: "0%",
        // y: "0%",
        // autoAlpha: 1,
        // width: "100%",
        // height: "100%",
        css: { top: 0, left: 0, opacity: 1, visibility: 'visible', width: '100%', height: '100%' },
        ease: Power1.easeInOut
      })
    // .to(
    //   this.cover,
    //   half,
    //   {
    //     //   autoAlpha: 1,
    //     x: 0,
    //     y: 0,
    //     autoAlpha: 1,
    //     width: '100%',
    //     height: '100%',
    //     //   css:{position:'fixed', opacity:1, visibility: 'visible', width: '100%', height: '100%'},
    //     ease: Power2.easeOut
    //   },
    //   `+=${wait}`
    // )
    // .set(node, { opacity: 0 });
  };

  moveInDirection = ({ props, direction, node }) => {
    return this.vertical({ props, direction, node });
  };

  newPageAnimation(entry, node) {
    const newPageAnim = new TimelineMax();
    newPageAnim
      .from(node.querySelectorAll('.single-article-img'), 0.2, { autoAlpha: 0 }, 0.5)
      .staggerFrom(
        node.querySelectorAll('.header-logo, h1, p, a'),
        1,
        { autoAlpha: 0 },
        0.1
      );
    return newPageAnim;
  }

  render() {
    const direction = this.props.direction || "left";
    const length = this.props.duration || 0.5;
    const {
      exit: removedExit,
      entry: removedEntry,
      cover: removedProp,
      ...props
    } = this.props;

    return (
      <>
        <TransitionPortal>
          <div
            className="slide-article-img-cover-wrap"
            ref={n => (this.cover = n)}
            style={{
              background: this.props.bg || "#002630",
              opacity: 0,
              visibility: "hidden",
              position: "fixed",
              zIndex: 99
            }}
          />
        </TransitionPortal>
        <TransitionLink ref={n => (this.imgLink = n)}
          exit={{
            length: length,
            // delay: 1.5,
            trigger: ({ exit, node }) =>
              this.moveInDirection({ props: exit, node, direction })
          }}
          entry={{
            delay: 1,
            trigger: ({ entry, node }) => this.newPageAnimation(entry, node)
          }}
          {...props}
        >
          {this.props.children}
        </TransitionLink>
        {/* <TransitionPortal>
          <div
            ref={n => (this.cover = n)}
            style={{
              position: "fixed",
              background: this.props.bg || "#4b2571",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              transform: "translateY(100%)"
            }}
          />
        </TransitionPortal> */}
      </>
    );
  }
}