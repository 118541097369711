import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { useStaticQuery, graphql, Link } from "gatsby"
import Swiper from 'react-id-swiper'
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import { TweenMax, TimelineMax, Linear } from "gsap/all"
// import CSSPlugin from 'gsap/CSSPlugin';
import TimelineArticleImgAnim from "../components/timelineArticleImgAnim"
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

// const C = CSSPlugin;

const HOME_TIMELINE_QUERY = gql`
  {  
    posts(first: 100, where: {is_featured: 1}) {
      edges {
        node {
          id
          uri
          status
          title
          frontline_articles_options {
            articleTemplate
            articleYear
            articleTitlePosition
            backgroundColor
            backgroundImage {
              sourceUrl
            }
            featuredImage {
              sourceUrl
            }
            yearPositionWithTheImage
            yearPositionWithTheTimeline
            yearSizeOverTheTimeline
            imagePositionOverTimeline
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
	const { loading, error, data } = useQuery(HOME_TIMELINE_QUERY);
	const [swiper, updateSwiper] = useState(null);
	const autoplayStart = () => {
		if (swiper !== null) {
			let slideWidth = 0;
			let windowWidth = $(window).width();
			let slideDuration = $(".slide-article-list .swiper-wrapper .swiper-slide").length * 0.90;

			$(".slide-article-list .swiper-wrapper .swiper-slide").each(function () {
				slideWidth += $(this).outerWidth(true);
			});

			let timelineMoveLeftPosition = slideWidth - windowWidth;
			let timelineSliderLeftTl = new TimelineMax({ paused: true });
			timelineSliderLeftTl
				.addLabel("startPoint")
				.to(".slide-article-list .swiper-wrapper", slideDuration, {
					x: "-" + timelineMoveLeftPosition,
					ease: Linear.easeNone,
					onUpdate: function () {
						swiper.setTranslate(swiper.getTranslate());
					}
				});

			$(".swiper-button-prev").hover(function () {
				timelineSliderLeftTl.tweenTo("startPoint");
			}, function () {
				TweenMax.killTweensOf(timelineSliderLeftTl);
			});

			$(".swiper-button-next").hover(function () {
				timelineSliderLeftTl.play();
				swiper.navigation.update();
			}, function () {
				timelineSliderLeftTl.pause();
			});
		}
	};

	if (loading) return null;
	if (error) return <p>ERROR: {error.message}</p>;

	// let i = 5
	// console.log(data.posts.edges[i]);
	// console.log("/" + data.posts.edges[i].node.uri);
	// console.log(data.posts.edges[i].node.frontline_articles_options.featuredImage.sourceUrl);
	// console.log(data.posts.edges[i].node.title);
	// console.log(data.posts.edges[i].node.frontline_articles_options.backgroundColor);
	// console.log(`url(${data.posts.edges[i].node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`);

	const articleData = data.posts.edges.map((edge) => {
		return edge.node.frontline_articles_options.articleTemplate === "single_article" ? <div className={`slide-article-item ${edge.node.frontline_articles_options.yearPositionWithTheTimeline} ${edge.node.frontline_articles_options.yearPositionWithTheImage} ${edge.node.frontline_articles_options.imagePositionOverTimeline} ${edge.node.frontline_articles_options.yearSizeOverTheTimeline} ${edge.node.frontline_articles_options.articleTitlePosition}`} key={edge.node.id}>
			{edge.node.frontline_articles_options.featuredImage !== null && <div className="slide-article-img">
				<img src={edge.node.frontline_articles_options.featuredImage.sourceUrl} alt="" />
				{edge.node.frontline_articles_options.backgroundImage !== null && <TimelineArticleImgAnim
					cover
					to={"/" + edge.node.uri}
					duration={2}
					style={{ backgroundImage: `url(${edge.node.frontline_articles_options.featuredImage.sourceUrl})` }} className="slide-article-img-cover"
					bg={`url(${edge.node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`}></TimelineArticleImgAnim>}

				{edge.node.frontline_articles_options.backgroundImage === null && <TimelineArticleImgAnim
					cover
					to={"/" + edge.node.uri}
					duration={2}
					style={{ backgroundImage: `url(${edge.node.frontline_articles_options.featuredImage.sourceUrl})` }} className="slide-article-img-cover"
					bg={edge.node.frontline_articles_options.backgroundColor}></TimelineArticleImgAnim>}

			</div>}
			<div className="slide-article-year">
				{edge.node.frontline_articles_options.backgroundImage !== null && edge.node.title !== null && <div className={"slide-article-title"}><TimelineArticleImgAnim
					cover
					to={"/" + edge.node.uri}
					duration={2}
					bg={`url(${edge.node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`}>{edge.node.title}</TimelineArticleImgAnim></div>}

				{edge.node.frontline_articles_options.backgroundImage === null && edge.node.title !== null && <div className={"slide-article-title"}><TimelineArticleImgAnim
					cover
					to={"/" + edge.node.uri}
					duration={2}
					bg={edge.node.frontline_articles_options.backgroundColor}>{edge.node.title}</TimelineArticleImgAnim></div>}

				{edge.node.frontline_articles_options.backgroundImage !== null && <TimelineArticleImgAnim
					cover
					to={"/" + edge.node.uri}
					duration={2}
					bg={`url(${edge.node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`}>{edge.node.frontline_articles_options.articleYear}</TimelineArticleImgAnim>}

				{edge.node.frontline_articles_options.backgroundImage === null && <TimelineArticleImgAnim
					cover
					to={"/" + edge.node.uri}
					duration={2}
					bg={edge.node.frontline_articles_options.backgroundColor}>{edge.node.frontline_articles_options.articleYear}</TimelineArticleImgAnim>}
				{/* {<Link to={"/" + edge.node.uri}>{edge.node.year}</Link>} */}
				{/* {<span>{edge.node.year}</span>} */}

				{/* {edge.node.title !== null && edge.node.title_position === "below_year" && <div className={"slide-article-title " + edge.node.title_position}><TimelineArticleImgAnim
            cover
            to={"/" + edge.node.uri}
            duration={2} 
            bg={`url(${edge.node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`}>{edge.node.title}</TimelineArticleImgAnim></div>} */}
			</div>
		</div> :

			<div className={`slide-article-item ${edge.node.frontline_articles_options.yearPositionWithTheTimeline} ${edge.node.frontline_articles_options.yearPositionWithTheImage} ${edge.node.frontline_articles_options.imagePositionOverTimeline} ${edge.node.frontline_articles_options.yearSizeOverTheTimeline} ${edge.node.frontline_articles_options.articleTitlePosition}`} key={edge.node.id}>
				{edge.node.frontline_articles_options.featuredImage !== null && <div className="slide-article-img">
					<img src={edge.node.frontline_articles_options.featuredImage.sourceUrl} alt="" />
					{edge.node.frontline_articles_options.backgroundImage !== null && <TimelineArticleImgAnim
						cover
						to={"/" + edge.node.frontline_articles_options.articleYear}
						duration={2}
						style={{ backgroundImage: `url(${edge.node.frontline_articles_options.featuredImage.sourceUrl})` }} className="slide-article-img-cover"
						bg={`url(${edge.node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`}></TimelineArticleImgAnim>}

					{edge.node.frontline_articles_options.backgroundImage === null && <TimelineArticleImgAnim
						cover
						to={"/" + edge.node.frontline_articles_options.articleYear}
						duration={2}
						style={{ backgroundImage: `url(${edge.node.frontline_articles_options.featuredImage.sourceUrl})` }} className="slide-article-img-cover"
						bg={edge.node.frontline_articles_options.backgroundColor}></TimelineArticleImgAnim>}

				</div>}
				<div className="slide-article-year">
					{edge.node.frontline_articles_options.backgroundImage !== null && edge.node.title !== null && <div className="slide-article-title"><TimelineArticleImgAnim
						cover
						to={"/" + edge.node.frontline_articles_options.articleYear}
						duration={2}
						bg={`url(${edge.node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`}>{edge.node.title}</TimelineArticleImgAnim></div>}

					{edge.node.frontline_articles_options.backgroundImage !== null && <TimelineArticleImgAnim
						cover
						to={"/" + edge.node.frontline_articles_options.articleYear}
						duration={2}
						bg={`url(${edge.node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`}>{edge.node.frontline_articles_options.articleYear}</TimelineArticleImgAnim>}

					{edge.node.frontline_articles_options.backgroundImage === null && <TimelineArticleImgAnim
						cover
						to={"/" + edge.node.frontline_articles_options.articleYear}
						duration={2}
						bg={edge.node.frontline_articles_options.backgroundColor}>{edge.node.frontline_articles_options.articleYear}</TimelineArticleImgAnim>}
					{/* {<Link to={"/" + edge.node.uri}>{edge.node.year}</Link>} */}
					{/* {<span>{edge.node.year}</span>} */}

					{/* {edge.node.title !== null && edge.node.title_position === "below_year" && <div className={"slide-article-title " + edge.node.title_position}><TimelineArticleImgAnim
          cover
          to={"/" + edge.node.uri}
          duration={2} 
          bg={`url(${edge.node.frontline_articles_options.backgroundImage.sourceUrl}) center / cover no-repeat`}>{edge.node.title}</TimelineArticleImgAnim></div>} */}
				</div>
			</div>
	}
	);
	const params = {
		spaceBetween: 40,
		slidesPerView: 'auto',
		freeMode: true,
		updateOnWindowResize: true,
		// simulateTouch: false,
		// freeModeSticky: true,
		// speed: 2000,
		// autoplay: {delay: 0, stopOnLastSlide: true},
		// autoplayDisableOnInteraction: false
		breakpoints: {
			992: {
				simulateTouch: false,
				freeModeMomentum: false,
				freeModeMomentumBounce: false,
			}
		},
		on: {
			init: function () {
				$(".slide-article-list .swiper-button-next").addClass("show-arrow");
				$(".slide-article-list .swiper-button-next").mouseenter(function () {
					if (!$(".slide-article-list .swiper-button-prev").hasClass("show-arrow")) {
						$(".slide-article-list .swiper-button-prev").addClass("show-arrow");
					}
				});
				$(".slide-article-list .swiper-button-prev").mouseenter(function () {
					if (!$(".slide-article-list .swiper-button-next").hasClass("show-arrow")) {
						$(".slide-article-list .swiper-button-next").addClass("show-arrow");
					}
				});
			},
			reachBeginning: function () {
				$(".slide-article-list .swiper-button-prev").removeClass("show-arrow");
			},
			reachEnd: function () {
				$(".slide-article-list .swiper-button-next").removeClass("show-arrow");
			}
		}
		// navigation: {
		//   nextEl: '.swiper-button-next',
		//   prevEl: '.swiper-button-prev'
		// }
	}

	return (
		<Layout animatedHeader="animatedHeader">
			<SEO title="Home" />
			<div className="home-globe-bg"></div>
			<div className="slide-article-list" onMouseEnter={autoplayStart}>
				<Swiper {...params} getSwiper={updateSwiper}>
					{articleData}
				</Swiper>
				<div className="swiper-button-prev"><span></span></div>
				<div className="swiper-button-next"><span></span></div>
				<div className="article-timeline"></div>
			</div>
		</Layout>
	)
}

export default IndexPage
